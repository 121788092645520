///include /assets/js/app/p/catalogue.js
"use strict"
/**
 * This is the full product catalogue
 */
class UserCatalogue extends Catalogue {
    static get inst() {
        if(!this._inst) {
            this._inst = new UserCatalogue()
        }
        return this._inst
    }
    constructor() {
        super()
        /**
         * @type {?GET.Stack.Catalogue.$ns.catalogue.userConfig.output}
         */
        this.userConfig = null
        /** @type {?number} eg. 20 */
        this.userVATRate = null
        /** @type {?GET.Stack.Catalogue.$ns.catalogue.productDescription.output} */
        this.productDescription = null

        /**
         * @type {{[type: string]: SBProductPricing}}
         */
        this.lowPriceCache = {}

        /**
         * @type {{[type: string]: SBProductPricing}}
         */
         this.lowPriceCacheCustomCategory = {}
    }
    get networkPropertyHandlers() {
        return Object.assign(
            super.networkPropertyHandlers,
            {
                userConfig: async () => {
                    if(typeof PaymentItemCollection != "undefined") {
                        const pic = PaymentItemCollection.inst
                        try {
                            /** @type {{[ref: string]: string}} */
                            const payment_item_countries =
                                await pic.preloadSingle("countries")

                            return await $.ajax({
                                method: "get",
                                url: `/a/catalogue/userConfig`,
                                data: {
                                    paymentItemCountries: payment_item_countries,
                                }
                            })
                        } catch(e) {
                            console.log(e)
                        }
                    }
                    return await $.ajax(`/a/catalogue/userConfig`)
                },
                userVATRate: () => this.preloadSingle("userConfig").then(
                    v => v.vatRate
                ),
                productDescription: () =>  $.ajax(`/a/catalogue/productDescription`)
            }
        )
    }

    get nonHidddenProducts() {
        if (!this.products) return null
        return this.products.filter(p => !p.hidden)
    }

    /**
     * If no catalogue, redirect
     */
    async assertExists() {
        try {
            await this.preloadSingle("config")
        } catch(e) {
            console.log("No catalogue config")
            location.href = "/?" + $.param({"no-config": location.pathname})
        }
    }

    /**
     * @param {string} code
     */
    productDescriptionFromCode(code, lang = "en-GB") {
        if( this.productDescription && this.productDescription[code] && this.productDescription[code].length ) {
            const m = this.productDescription[code].find(el => el.language === lang) || this.productDescription[code].find(el => el.language === "en-GB")
            if(m) {
                return m.content
            }
        } else {
            return null;
        }
    }

    /**
     * Returns the cheapest price of the cheapest product of a type.
     *
     * @param {string} type
     */
    lowestPriceOfType(type) {
        if(!this.nonHidddenProducts) return null
        if(!this.lowPriceCache[type]) {
            /** @type {?SBProductPricing} */
            let cheapest = null
            var products = this.nonHidddenProducts.filter( (p) => {
                switch(type) {
                    case 'managed_vps':
                        return p.codePrefix == type && !p.code.match(/^cloud_server/);
                    case 'cloud_server':
                        return p.code.match(/^cloud_server/);
                    default:
                        return p.codePrefix == type;
                }
            } );
            for(const p of products) {
                if(!p.lowestPrice || p.lowestPrice.price === null) continue
                if(!cheapest) {
                    cheapest = p.lowestPrice
                } else if(
                    p.codePrefix === "domain" &&
                    cheapest.managedMarkedUpPrice !== null &&
                    p.lowestPrice.managedMarkedUpPrice < cheapest.managedMarkedUpPrice
                ) {
                    cheapest = p.lowestPrice
                } else if(
                    cheapest.price !== null &&
                    p.lowestPrice.price < cheapest.price
                ) {
                    cheapest = p.lowestPrice
                }
            }
            if(cheapest) {
                this.lowPriceCache[type] = cheapest
            }
        }
        return this.lowPriceCache[type]
    }

    /**
     * Returns the cheapest price of the cheapest product of a category.
     *
     * @param {string} type
     */
     lowestPriceOfCustomCategory(category, products) {
        if(!this.nonHidddenProducts) return null
        if(!this.lowPriceCacheCustomCategory[category]) {
            /** @type {?SBProductPricing} */
            let cheapest = null
            for(const p of this.nonHidddenProducts.filter(p => products.includes(p.code))) {
                if(!p.lowestPrice || p.lowestPrice.price === null) continue
                if(!cheapest) {
                    cheapest = p.lowestPrice
                } else if(
                    cheapest.price !== null &&
                    p.lowestPrice.price < cheapest.price
                ) {
                    cheapest = p.lowestPrice
                }
            }
            if(cheapest) {
                this.lowPriceCacheCustomCategory[category] = cheapest
            }
        }
        return this.lowPriceCacheCustomCategory[category]
    }
}
